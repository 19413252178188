import React, { useState } from 'react'
import styled from 'styled-components'
import BaseButton from '../../baseButton/BaseButton'
import LinkButton from '../../linkButton/LinkButton'
import $api from '../../../http'

const PriceItem = ({ name, image, descr, payments, user, theme, link, price, durationText }) => {
   const [isOpen, setIsOpen] = useState(false)

   const toggleOpen = () => {
      setIsOpen(!isOpen)
   }

   const formatingNumber = (number) => {
      return number ? number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : '0'
   }

   const [natification, setNatification] = useState('')

   const paymentFn = async () => {
      if (user?.balance < payments?.price_per_year) {
         setNatification('У Вас не достаточно средств!')
         setTimeout(() => {
            setNatification('')
            window.location = '/profile'
         }, 3000)
      } else {
         try {
            await $api.post('/subscription', {
               subscription_id: payments?.id,
            })
            setNatification(`Вы успешно преобрели подписку "${name}"`)
            setTimeout(() => {
               setNatification('')
            }, 3000)
         } catch {
            setNatification('Что-то пошло не так, попробуйте позже!')
            setTimeout(() => {
               setNatification('')
            }, 3000)
         }
      }
   }

   return (
      <Wrapper name={name} onClick={toggleOpen}>
         <Title>
            <div>{name}</div>
            {name !== 'Базовый' && <img src={image} alt='icon' />}
         </Title>
         <Content isOpen={isOpen}>
            {Array.isArray(descr) ? (
               <ul>
                  {descr.map((item) => (
                     <li key={item}>{item}</li>
                  ))}
               </ul>
               ) : (
                  <p dangerouslySetInnerHTML={{ __html: descr }} />
            )}
            <ActivePrice>
               <p>{!link?.lenght ? formatingNumber(payments?.price_per_year) : '0'} CUN {durationText}</p>
               {!!link?.length ? (
                  <Active>
                     <BaseButton text={'Купить'} link={link} />
                     <LinkButton color={name === 'Эксперт' ? 'white' : ''} link={link} />
                  </Active>
               ) : (
                  <Active
                     onClick={(e) => {
                        e.stopPropagation()
                        paymentFn()
                     }}
                  >
                     <BaseButton text={'Купить'} />
                     <LinkButton color={name === 'Эксперт' ? 'white' : ''} />
                  </Active>
               )}
            </ActivePrice>
         </Content>
         {!!natification && <Natification theme={theme}>{natification}</Natification>}
      </Wrapper>
   )
}

export default PriceItem

const Natification = styled.div`
   padding: 10px 20px;
   font-size: 14px;
   border-radius: 8px;
   position: fixed;
   bottom: 30px;
   right: 30px;
   box-shadow: 1px 2px 20px gray;
   z-index: 1;

   ${(props) =>
      props.theme === 'light' ? 'background: #000; color: #fff;' : 'background: #fff; color: #000;'}
`

const Active = styled.div`
   display: flex;
`

const ActivePrice = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   p {
      font-size: 19px;
      font-weight: 700;
      line-height: 31.2px;
      letter-spacing: -0.02em;
      font-family: system-ui;
   }
`

const Title = styled.div`
   font-size: 24px;
   max-height: 11vh;
   font-weight: 700;
   line-height: 31.2px;
   letter-spacing: -0.02em;
   display: flex;
   justify-content: space-between;
`

const Wrapper = styled.div`
   border-radius: 16px;
   padding: 25px 25px 15px 20px;
   margin-bottom: 14px;
   background: ${(props) =>
      props.name === 'Энтузиаст'
         ? 'linear-gradient(320deg, rgba(80,99,113,1) 30%, rgba(82,97,116,1) 100%)'
         : props.name === 'Стратег'
         ? '#23323F'
         : props.name === 'Эксперт'
         ? 'linear-gradient(320deg, rgba(161,252,94,1) 30%, rgba(203,255,153,1) 100%)'
         : 'linear-gradient(320deg, rgb(155, 155, 155) 30%, rgb(89 89 89) 100%)'};
   color: ${(props) => (props.name === 'Лидер' ? '#000' : '#fff')};
   ul {
      margin-top: 25px;
      padding-left: 20px;
   }
   li {
      font-size: 14px;
      font-weight: 400;
      line-height: 18.2px;
      letter-spacing: -0.02em;
      margin-bottom: 10px;
   }
`
const Content = styled.div`
   max-height: ${(props) => (props.isOpen ? '1000px' : '0')};
   overflow: hidden;
   transition: max-height 0.3s ease-in-out;
`
//max-height: ${(props) => (props.isOpen ? '500px' : '0')};
//display: ${(props) => (props.isOpen ? 'block' : 'none')};