import LinkButton from '../linkButton/LinkButton'
import classes from './Profile.module.css'
import CopuIcon from '../../image/copuIcon.svg'
import PayFromIcon from "../../image/FromPay.svg";
import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { goToProfilePage } from '../../store/actions/appActions';
import {getCurrenciesAsync} from '../../store/thunks/appThunks';
import Modal from '../modal/Modal'
import ModalDepProfile from '../modalsGrid/ModalDepProfile'
import GridBotLink from '../gridBotLink/GridBotLink';
import ModalFromCash from '../modalGridCash/ModalFromCash';
import ModalGridCash from '../modalGridCash/ModalGridCash';
const Profile = ({ photoUrl, refValue, userName, theme, balance, subscribes }) => {
   const dispatch = useDispatch()

   const subscription = useSelector((state) => state?.app?.subscription?.name)
   const currencies = useSelector((state) => state);
   const [natification, setNatification] = useState(false)
   const copuFn = (text) => {
      navigator.clipboard.writeText(text)
      setNatification(true)
      setTimeout(() => setNatification(false), 2000)
   }
   const linkActionsArr = [
      { name: "Вывести средства", imgUrl: PayFromIcon, label: "Вывод" },
    ];
   const formatNumber = (number) => {
      return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
   }
   const [showFullText, setShowFullText] = useState(false);

   const [activeModal, setActiveModal] = useState("");
   const [showModal, setShowModal] = useState()

   const translateSub = () => {
      if (subscribes === 'enthusiast') {
         return 'Энтузиаст'
      } else if (subscribes === 'strategist') {
         return 'Стратег'
      } else if (subscribes === 'lider') {
         return 'Эксперт'
      } else return 'Базовый'
   }

   useEffect(() => {
      dispatch(getCurrenciesAsync());
   },[]);
   return (
      <ProfileWrapper className={classes.profileWrapper + ' wrapper'}>
         <UserInfoWrapper
            href={'/profile'}
            onClick={() => {
               dispatch(goToProfilePage())
            }}
         >
            <UserInfo>
               <Image>
                  {!!photoUrl ? (
                     <img src={photoUrl} alt='avatar' className={classes.initialPhoto} />
                  ) : (
                     <InitialPhoto theme={theme} className={classes.initialPhoto}>
                        {userName.split(' ').length > 1
                           ? `${userName[0][0] || ''} ${userName.split(' ')[1][0] || ''}`
                           : userName[0][0] || ''}
                     </InitialPhoto>
                  )}
               </Image>
               <Contact>
                  <Name theme={theme}>{userName}</Name>
                  <div className={classes.nick}>{translateSub()}</div>
                  {window.location.pathname === '/profile' && subscription ? (
                     <div className={classes.nick}>{subscription}</div>
                  ) : null}
               </Contact>
               {/* {window.location.pathname === '/profile' ? (
                  // && balanceRub
                  <Balance theme={theme}>
                     {balanceRub?.toString()}CUN/{balanceUsd?.toString()}$
                  </Balance>
               ) : null} */}
            </UserInfo>

            {!refValue && (
               <LinkWrapper>
                  <LinkButton />
               </LinkWrapper>
            )}
         </UserInfoWrapper>

         {refValue && (
            <RefWrapper theme={theme}>
               <p>Ваша реферальная ссылка:</p>
               <RefLink
                  className={classes.reflink}
                  onClick={() => copuFn(`https://t.me/delo_app_bot?start=${refValue}`)}
               >
                  <p>
                     delo_app_bot?start=
                     {refValue.length > 11 ? refValue.slice(0, 10) + '...' : refValue}
                  </p>
                  <img src={CopuIcon} alt='' />
               </RefLink>
            </RefWrapper>
         )}
         {window.location.pathname === '/profile' && (
            <BalanceWrapper theme={theme}>
               <div>
                  <PayText>Ваш баланс:</PayText>
                  <Price>{formatNumber(balance)} CUN</Price>
               </div>
               <Button onClick={() => setShowModal(true)}>Пополнить</Button>
            </BalanceWrapper>
         )}
         {window.location.pathname === '/profile' && (subscribes === 'strategist' || subscribes === 'lider') && (
            <GridBotLink
            key={linkActionsArr[0].name}
            {...linkActionsArr[0]}
            handleClick={() => {
              setActiveModal(linkActionsArr[0].label);
              setShowFullText(false);
            }}
            theme={theme}
          />
         )}
         {!!activeModal.length && (
            <ModalGridCash onCloseModal={() => setActiveModal("")} theme={theme}>
                <ModalFromCash
                  theme={theme}
                  onCloseModal={() => setActiveModal("")}
               />
            </ModalGridCash>
         )}
         {natification && (
            <Natification theme={theme} className={classes.natification}>
               Ссылка успешно скопирована!
            </Natification>
         )}

         {showModal && (
            <ModalGridCash onCloseModal={() => setShowModal(false)} theme={theme}>
               <ModalDepProfile onCloseModal={() => setShowModal(false)} theme={theme} />
            </ModalGridCash>
         )}
      </ProfileWrapper>
   )
}

export default Profile

const PayText = styled.div`
   font-size: 15px;
   font-weight: 400;
`

const Button = styled.div`
   font-size: 14px;
   padding: 10px 34px;
   background: #beff8b;
   border-radius: 28px;
   cursor: pointer;
   transition: all 0.3s ease;
   color: #000;
   height: 40px;
   padding: 0 30px;
   display: flex;
   align-items: center;
   &:hover {
      box-shadow: 1px 2px 20px gray;
   }
`

const Price = styled.div`
   font-size: 20px;
   font-weight: 700;
   margin-top: 4px;
   font-family: system-ui;
`

const BalanceWrapper = styled.div`
   display: flex;
   justify-content: space-between;
   padding: 26px 20px 10px 18px;
   align-items: end;
   ${(props) => (props.theme === 'light' ? 'color: #000;' : 'color: #fff;')}
`

const InitialPhoto = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   letter-spacing: -1px;
   font-size: 18px;
   border-radius: 50%;
   height: 39px;
   width: 39px;
   font-weight: 600;
   ${(props) =>
      props.theme === 'light'
         ? 'background: #b05edc; color: #000;'
         : 'background: #9f14b6; color: #fff;'}
`
// const Balance = styled.div`
//    margin-right: 30px;
//    flex-grow: 1;
//    text-align: right;
//    font-size: 14px;
//    font-weight: 600;
//    color: ${(props) => (props.theme === 'light' ? '#11141b' : '#fff')};
// `
const Natification = styled.div`
   padding: 10px 20px;
   font-size: 14px;
   border-radius: 8px;
   position: fixed;
   bottom: 20px;
   right: 20px;
   ${(props) =>
      props.theme === 'light' ? 'background: #000; color: #fff;' : 'background: #fff; color: #000;'}
`

const RefLink = styled.div`
   border: 1px solid #5f7385;
   border-radius: 30px;
   display: flex;
   justify-content: space-between;
   padding: 0px 29px 0 25px;
   width: 81%;
   transition: all 0.3s ease;

   p {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      letter-spacing: -0.02em;
      color: #5f7385;
      margin: 18px 0;
   }
   &:hover {
      box-shadow: 1px 2px 15px gray;
      cursor: pointer;
   }
`

const RefWrapper = styled.div`
   margin: 26px 6px 0px 18px;
   p {
      font-size: 14px;
      font-weight: 400;
      line-height: 15.4px;
      color: ${(props) => (props.theme === 'light' ? '#11141b' : '#fff')};
   }
`

const LinkWrapper = styled.div`
   margin-right: 20px;
`

const Name = styled.div`
   font-size: 14px;
   font-weight: 400;
   line-height: 16.8px;
   letter-spacing: -0.02em;
   color: ${(props) => (props.theme === 'light' ? '#11141b' : '#fff')};
`

const Contact = styled.div`
   margin-left: 9px;
`

const Image = styled.div`
   margin-left: 16px;
   > img {
      height: 39px;
      width: 39px;
   }
`

const UserInfo = styled.div`
   display: flex;
   align-items: center;
   width: 100%;
`

const UserInfoWrapper = styled.a`
   display: flex;
   justify-content: space-between;
   width: 100%;
   align-items: center;
`

const ProfileWrapper = styled.div`
   border-radius: 16px;
   padding: 17px 0px 17px 0;
`
