import React, { useMemo, useState } from 'react'
import PriceItem from './PriceItem/PriceItem'
import Lamp from '../../image/lampIcon.svg'
import InfoIcon from '../../image/infoIcon.svg'
import KingItem from '../../image/kingIcon.svg'
import styled from 'styled-components'
import $api from '../../http'

var pricesArr = [
   {
      name: 'Базовый',
      enName: 'base',
      price: '0',
      id: 0,
      descr: [
         'Бонус реферальной программы;',
         'Выдаётся бесплатно на 3 месяца, после активации аккаунта;',
      ],
      link: '/profile',
   },
   {
      name: 'Энтузиаст',
      enName: 'enthusiast',
      image: Lamp,
      id: 1,
      descr: [
         'Доступ к мероприятиям клуба (два мероприятия в месяц и более);',
         'Подключение к интернет-платформе клуба — для активного нетворкинга и общения с единомышленниками в чате;',
         'Возможность участвовать в реферальной программе и получать бонусы от нахождения в клубе и приглашения друзей;',
      ],
   },
   {
      name: 'Стратег',
      enName: 'strategist',
      image: InfoIcon,
      id: 2,
      descr: [
         'Все преимущества тарифа «Энтузиаст».',
         'Расширенный доступ к мастер-классам и образовательным ресурсам (все курсы клуба в записи);',
         'Участие в эксклюзивных вебинарах с приглашенными экспертами;',
         'Персональные консультации от экспертов клуба — 4 консультации в месяц для постоянного роста;',
         'Возможность приобретать товары и услуги от партнеров клуба по эксклюзивным условиям;',
      ],
   },
   {
      name: 'Эксперт',
      enName: 'lider',
      image: KingItem,
      id: 3,
      descr: [
         `Все преимущества тарифов "Энтузиаст" и "Стратег".`,
         'Возможность продвигать свой бизнес или услуги в клубе с помощью управленческого совета клуба;',
         'Персональные расширенные консультации по цифровой психологии, бизнесу и личностному росту;',
         'Возможность стать спикером и делиться своим опытом с участниками на мероприятиях клуба;',
         'Доступ к закрытому чату с управленческим советом клуба;',
      ],
   },
]

const Prices = ({ theme, user }) => {
   const [payments, setPayments] = useState([])

   useMemo(async () => {
      const response = await $api.get('/subscriptions')
      setPayments(response.data)
      response.data.forEach((item) => {
         const index = pricesArr.findIndex((price) => price.enName === item.name);
         if (index !== -1 && (item.price_per_year || item.description)) {
            pricesArr[index] = {
               ...pricesArr[index],
               price: item.price_per_year,
               descr: item.description ? item.description : pricesArr[index].descr,
               durationText: item?.duration_text,
            };
         }
      });

   }, [])

   const checkPayments = (paymentsMethod) => {
      switch (paymentsMethod) {
         case 'Энтузиаст':
            return payments.filter((item) => item.name === 'enthusiast')[0]
         case 'Стратег':
            return payments.filter((item) => item.name === 'strategist')[0]
         case 'Эксперт':
            return payments.filter((item) => item.name === 'lider')[0]
         default:
            return {}
      }
   }

   return (
      <Wrapper className={'wrapper'}>
         <Header>
            <Title theme={theme}>Выбери свой тариф</Title>
         </Header>
         {pricesArr &&
            pricesArr.map((item) => (
               <PriceItem
                  {...item}
                  key={item?.name}
                  payments={checkPayments(item.name)}
                  user={user}
               />
            ))}
      </Wrapper>
   )
}

export default Prices

const Header = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 25px;
`

const Title = styled.div`
   font-size: 30px;
   font-weight: 700;
   line-height: 39px;
   letter-spacing: -0.02em;
   color: ${(props) => (props.theme === 'light' ? '#11141b' : '#fff')};
`
const Wrapper = styled.div`
   width: 100%;
   margin-top: 80px;
   position: relative;
`
