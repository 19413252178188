import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';

const slideIn = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const NotificationWrapper = styled.div`
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: #1E1E1E;
  border-radius: 12px;
  padding: 16px 20px;
  color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  display: flex;
  align-items: center;
  gap: 12px;
  animation: ${slideIn} 0.3s ease-out forwards;
  border: 1px solid rgba(255, 255, 255, 0.1);

  &::before {
    content: '';
    width: 8px;
    height: 8px;
    background: #9EFF00;
    border-radius: 50%;
    margin-right: 4px;
  }
`;

const SubscriptionNotification = ({date}) => {
  const [daysLeft, setDaysLeft] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    if (!date) return;

    const calculateDaysLeft = () => {
      const expirationDate = new Date(date);
      const now = new Date();
      const diffTime = expirationDate - now;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    };

    const days = calculateDaysLeft();
    setDaysLeft(days);
    setShowNotification(days <= 3 && days > 0);
  }, [date]);

  if (!showNotification) return null;

  return (
    <NotificationWrapper>
      До окончания подписки осталось {daysLeft} {
        daysLeft === 1 ? 'день' : 
        daysLeft === 2 || daysLeft === 3 ? 'дня' : 'дней'
      }
    </NotificationWrapper>
  );
};

export default SubscriptionNotification;