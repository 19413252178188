import Community from '../../components/community/Community'
import About from '../../components/about/About'
import Description from '../../components/description/Description'
import Profile from '../../components/profile/Profile'
import classes from './MainPage.module.css'
import Prices from '../../components/prices/Prices'
import ChampionBlock from '../../components/chempionBlock/ChampionBlock'
import Gallery from '../../components/gallery/Gallery'
import SocialLink from '../../components/socialLink/SocialLink'
// import Offer from "../../components/offer/Offer";
import { useTelegram } from '../../hooks/useTelegram'
import IPCreds from '../../components/IPCreds/IPCreds'
import { useMemo, useState } from 'react'
import $api from '../../http'
import SubscriptionNotification from '../../components/subscriptionNotification/SubscriptionNotification'

const MainPage = ({ userBack }) => {
   const { webApp, user } = useTelegram()

   const theme = webApp.colorScheme
   const userName = `${user?.first_name} ${user?.last_name}`
   
   const [mainPageImages, setMainPageImages] = useState({})
   useMemo(async () => {
      const response = await $api.get(`/main_page_images`)
      setMainPageImages(response.data)
   }, [])

   if (!userBack) return null
   else{
      const subscription_expired_at = userBack.subscription_expired_at;
      const subscription = userBack.subscription;
      return (
         <div className={classes.wraper}>
            <Profile
               userName={userName}
               subscribes={userBack?.subscription?.name}
               theme={theme}
               photoUrl={userBack?.avatar_url}
            />
            <Description />
            <Community theme={theme} />
            <About theme={theme} subscription={subscription}/>
            {/*<Offer theme={theme} />*/}
            <Prices theme={theme} user={userBack} />
            <ChampionBlock theme={theme} />
            <Gallery images={mainPageImages}/>
            <SocialLink />
            <IPCreds theme={theme} />
            <SubscriptionNotification date={subscription_expired_at}/>
         </div>
      )
   }
}

export default MainPage
